<template>
<div class="animated fadeIn fit">
    <h3 class="mb-3 text-dark">{{ translate('personal_files') }}</h3>

    <search-wrapper-card class="dark-border mb-2 p-0" :no_filters="true" :fullwidth_textinput="true">
        <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternamecustomers" class="search-input" type="text" :placeholder="translate('find_client')" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
        </q-input>
    </search-wrapper-card>

    <!-- [+] Clients list with links to their personal file. -->
    <q-list class="wrapper_personal-files_customers">
        <q-item class="list-item" v-for="(customer,i) in customers" clickable v-ripple :key="'customer-' + i + '-' + customer.id" :to="{name: 'PersonalFile', params: {id: customer.id}}">
            <q-item-section avatar class="p-0">
                <q-avatar class="shadow-2 p-0">
                    <img v-if="customer.image_link" class="black-and-white" :src="customer.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <q-item-label class="text-muted"><strong>{{ customer.name }}</strong></q-item-label>
            </q-item-section>
        </q-item>
    </q-list>
    <infinite-loading :identifier="'customers-' + infCustomers" slot="append" @infinite="getCustomers" />
    <!-- [-] Clients list with links to their personal file. -->
</div>
</template>

<script>
import _ from 'lodash'
import SearchWrapperCard from '../template-parts/SearchWrapperCard'

export default {
    name: 'PersonalFiles',
    components: {
        'search-wrapper-card': SearchWrapperCard,
    },
    created: function () {
        if (this.type_customer === true) {
            // Redirect to Home.
            this.$router.push({
                name: 'Home'
            }, undefined, () => { 
                location.href = this.$route.hash 
            })
        }
    },
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            page: 1,
            infCustomers: +new Date(),
            customers: [],
            user_status_active: 'active',
            state: {},
            searchQuery: '',
            isTyping: false,
        }
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.customers = []
            this.infCustomers++
        },
        getCustomers: function ($state) {
            var url = baseUrl +
                    'customers?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page +
                    '&customer_status=' + this.user_status_active + 
                    (this.searchQuery ? '&search=' + this.searchQuery : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let customers = this.customers
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        customers.push(...response.data.items.map(entity => {
                            let array = entity
                            if (entity.image_link) {
                                entity['image_link'] = baseUrl + entity.image_link
                            }
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.customers = customers
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.dark-border {
    border-style: solid!important;
    border-width: 1px!important;
    border-color: #ccc!important;
}
.q-item.list-item {
    max-width: 100%;
    border-radius: 0;
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    transition: border-color .1s ease-in-out;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img.black-and-white {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    .q-item__section--avatar {
        min-width: 50px;
    }
    &:hover {
        border-color: rgba(0, 0, 0, .26)!important;
        background-color: #fff!important;
        text-decoration: none;
        color: inherit;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
.input-group label.search-input {
    margin-left: -15px!important;
    margin-right: -15px!important;
    width: calc(100% + 15px)!important;
}
</style>
